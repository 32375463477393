<template>
  <b-card-code title="جدول الاخبار" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
          label="فلتر"
          label-cols-sm="1"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="title"
              type="search"
              placeholder="ابحث عن .."
            />
            <b-input-group-append>
              <b-button @click="resetSearch()"> الغاء الفلتر </b-button>
              <b-button
                v-if="role"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-modal.model-a-w
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">اضافة كتاب رسمي جديد</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <b-form-row style="margin-top: 10px">
        <b-col md="3">
          <b-form-group>
            <v-select
              v-model="search.type_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.types"
              placeholder="اختر نوع الكتاب"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <v-select
              v-model="search.subtype_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.subtypes"
              placeholder="اختر النوع الفرعي"
            />
          </b-form-group>
        </b-col>
        <b-col md="3"
          >
            <b-form-group
            >
              <cleave
                id="date"
                v-model="search.fromdate"
                class="form-control"
                :raw="false"
                :options="date"
                placeholder="من YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
          <b-col md="3"
          >
            <b-form-group
            >
              <cleave
                id="date"
                v-model="search.todate"
                class="form-control"
                :raw="false"
                :options="date"
                placeholder="الى YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
      </b-form-row>
    </b-card-body>
<div class="demo-vertical-spacing">
      <b-progress
      v-if="precentage !== 0"
        :value="precentage"
        max="100"
      />
      </div>
    <b-table
      ref="table"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="items"
      :fields="fields"
    >
    <template #cell(action)="action">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
            <b-col v-if="role" md="3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-warning"
                v-b-modal.model-e-w
                @click="eRM(action.item)"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
              </b-button>
            </b-col>
            <b-col v-if="role" v-show="action.item.is_deleted == 0" md="3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                v-b-modal.modal-center
                @click="dRM(action.item)"
              >
                <feather-icon icon="Trash2Icon" class="mr-50" />
              </b-button>
            </b-col>

            <b-col v-if="canDelete" v-show="action.item.is_deleted == 1" md="3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-info"
                @click="rDM(action.item.id)"
              >
                <feather-icon icon="AlertTriangleIcon" class="mr-50" />
              </b-button>
            </b-col>
            
        </b-dropdown>
      </template>
      <template #cell(users)="data">
        <b-badge
          v-for="stage in data.item.users"
          :key="stage.id"
          pill
          :variant="stage.pivot.is_confirm"
        >
        <router-link :to="{ name: 'employee-documents-list-show', params: { id: stage.id }}">
          {{ stage.full_name }}
        </router-link>
        </b-badge>
      </template>
      <template #cell(Document)="file">
        <b-button v-if="file.item.file!==null"
          v-ripple.400="'rgba(0, 207, 232, 0.15)'"
          variant="outline-info"
          @click="onClicked(file.item.file)"
        >
          عرض الكتاب
        </b-button>
        <b-button v-else
          v-ripple.400="'rgba(0, 207, 232, 0.15)'"
          variant="outline-danger"
          disabled
        >
          didn't uploaded 
        </b-button>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          @change="check()"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
        @change="check()"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <!-- <b-modal
      id="model-e-w"
      ok-title="تعديل البيانات"
      centered
      size="lg"
      title="تعديل البيانات"
      @show="checkModel"
      @hidden="rEF"
      @ok="uM"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <label for="input-valid1">العنوان بالعربي:</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.ar_title"
              :state="editform.ar_title.length > 0"
              placeholder="العنوان بالعربي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">العنوان بالانكليزي:</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.en_title"
              :state="editform.en_title.length > 0"
              placeholder="العنوان بالانكليزي"
            />
          </b-col>

          <b-col md="12">
            <label for="input-invalid1">مختصر للمقالة بالعربي:</label>
            <b-form-input
              id="input-invalid1"
              v-model="editform.ar_sub_description"
              :state="editform.ar_sub_description.length > 0"
              placeholder="مختصر للمقالة بالعربي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">مختصر للمقالة بالانكليزي:</label>
            <b-form-input
              id="input-invalid1"
              v-model="editform.en_sub_description"
              :state="editform.en_sub_description.length > 0"
              placeholder="مختصر للمقالة بالانكليزي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">المقال بالعربي:</label>
            <quill-editor
              ref="editformar_description"
              v-model="editform.ar_description"
              :options="snowOption"
            />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">المقال بالانكليزي:</label>
            <quill-editor
              ref="editformen_description"
              v-model="editform.en_description"
              :options="snowOption"
            />
          </b-col>
          <b-col v-if="role" md="12">
            <b-form-group>
              <label for="basic-password">القسم</label>
              <v-select
                v-model="editform.department_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="departments"
                placeholder="select department"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label for="input-valid1">اختصر الصورة الرئيسية:</label>
            <b-form-file
            placeholder="اختر الملف او اسقطها هنا..."
            drop-placeholder="اختر الملف او اسقطها هنا..."
              v-model="editform.file"
              @change="selectFileForEditForm"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">اختر الصور الاضافية:</label>
            <b-form-file
            placeholder="اختر الملف او اسقطها هنا..."
            drop-placeholder="اختر الملف او اسقطها هنا..."
              multiple
              ref="file"
              v-model="editform.files"
              @change="selectFilesForEditForm"
            />
          </b-col>
          <b-form-group>
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="editform.is_slider"
              name="flavour-2"
              class="demo-inline-spacing"
            >
              <b-form-checkbox value="1"> عرض في السلايدات </b-form-checkbox>
            </b-form-checkbox-group>
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="editform.is_aproved"
              name="flavour-2"
              class="demo-inline-spacing"
            >
              <b-form-checkbox value="1"> الموافقة على النشر </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-form-row>
      </b-form>
    </b-modal> -->
    <b-modal
      id="model-a-w"
      ok-title="حفظ البيانات"
      centered
      size="lg"
      title="اضافة كتاب رسمي"
      @ok="aNM"
      @hidden="rF"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">المرسل لهم</label>
              <v-select
                v-model="form.users"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="full_name"
                multiple
                :options="users"
                placeholder="اختر المنتسبين"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label for="input-valid1">الموضوع :</label>
            <b-form-input
              id="input-valid1"
              v-model="form.title"
              :state="form.title.length > 0"
              placeholder="الموضوع"
            />
          </b-col>
      <b-col md="12">
            <label for="input-invalid1">تفاصبل الكتاب:</label>
            <quill-editor
              ref="editformen_description"
              v-model="form.description"
              :options="snowOption"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">اختر ملف الكتاب (pdf.):</label>
            <b-form-file
            placeholder="اختر الصورة او اسقطها هنا..."
            drop-placeholder="اختر الصورة او اسقطها هنا..."
              v-model="form.file"
              @change="selectFileForForm"
            />
          </b-col>
          
        </b-form-row>
      </b-form>
    </b-modal>

    <!-- <b-modal
      id="modal-center"
      centered
      title="Delete User"
      @ok="dM"
      ok-title="Accept"
    >
      <b-card-text>
        هل تريد حذف هذه الحقل " {{ this.editform.ar_title }} "
        ?
      </b-card-text>
    </b-modal> -->
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import store from "@/store";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import emailjs from '@emailjs/browser';
import { filesUrl } from "@/main.js";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BFormTags,
  BAvatar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,
  VBModal,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BBadge,BProgress,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { quillEditor } from "vue-quill-editor";
export default {
  components: {
    BCardCode,Cleave,
    emailjs,
    BTable,
    BForm,
    quillEditor,
    vSelect,
    BAvatar,
    BFormTags,
    BFormRow,
    BDropdown,
    filesUrl,BProgress,
    BDropdownItem,
    BFormGroup,
    togglePasswordVisibility,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,BBadge,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BFormFile,
  },
  data() {
    return {
      precentage:0,
      teacher_id: "",
      perPage: 3,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      role: null,
      canEdit:null,
      canDelete: null,
      currentPage: 0,
      department: "",
      title: "",
      iD:"",
      item: "",
      id: "",
      filter: null,
      filterOn: [],
      departments: [],
      teachers: [],
      users: [],
      search:{
        types: [],
        subtypes: [],
        type_id: "",
        subtype_id: "",
        fromdate:"",
        todate:""
      },
      fields: [
        {
          key: "number",
          label: "رقم الكتاب",
        },
        {
          key: "title",
          label: "الموضوع",
        },
        {
          key: "date",
          label: "التاريخ",
        },
        {
          key: "type.ar_title",
          label: "نوع الكتاب",
        },
        {
          key: "subtype.ar_title",
          label: "النوع الفرعي",
        },
        {
          key: "Document",
          label: "الكتاب",
        },
        { key: "users", label: "محول الى" },
        { key: "action", label: "الاجراءات" },
      ],
      exportFields:{
          'No': 'number',
          'Title': 'title',
          'Type': 'type.ar_title',
          'Subtype': 'subtype.ar_title',
          'Employees': 'users.full_name',
      },
      exportData:[
        {
          No: "number",
          Title: "title",
          Type: "type.ar_title",
          Subtype: "subtype.ar_title",
          Employees: "users.full_name",
        },
      ],
      items: [],
      notifi: {
        route: "",
        title: "",
        item_id: "",
        table: "",
        users: [],
        departments: [],
        user_id:"",
        teacher_id:"",
        role_id: "",
        type: "",
        subtitle: "",
        bg: "",
      },
      form: {
        title: "",
        number: "",
        date: "",
        type_id: "",
        subtype_id: "",
        description: "",
        file: "",        
        users: [],
      },
      editform: {
        title: "",
        number: "",
        date: "",
        type_id: "",
        subtype_id: "",
        description: "",
        file: "",
        users: [],
        id: null,
      },
      date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
          },
      toEmail:false,
      toName:false,
      subject:null,
      toID:null,
      m:null,
      toUserID:null,
      types:[],
      subtypes:[],
      formModal: {},
      snowOption: {
        theme: "snow",
      },
      slider:"",
    };
  },
  watch: {
    title: function (val) {
      this.gS();
    },
    "$route.params.id": {
      handler: function (search) {
        this.gS();
      },
    },
    "search.type_id": function (val) {
      if (val != null || val != "") {
        this.gS()
      }
    },
    "search.fromdate": function (val) {
      if (val != null || val != "") {
        this.gS()
      }
    },
    "search.todate": function (val) {
      if (val != null || val != "") {
        this.gS()
      }
    },
    "search.subtype_id": function (val) {
      if (val != null || val != "") {
        this.gS()
      }
    },
    // "editform.is_slider": function (val) {
    //   if (this.selected2=='slider') {
    //     this.editform.is_slider = 1
    //       console.log(this.editform.is_slider)
    //     }else{
    //     this.editform.is_slider = 0
    //      console.log(this.editform.is_slider)
    //     }
        
    // },
    "editform.ar_description": function (val) {
      this.formModal.ar_edit = JSON.stringify(
        this.$refs.editformar_description.quill.getContents()
      );
    },
    "editform.en_description": function (val) {
      this.formModal.en_edit = JSON.stringify(
        this.$refs.editformen_description.quill.getContents()
      );
    },
    "form.ar_description": function (val) {
      this.formModal.ar_description = JSON.stringify(
        this.$refs.formar_description.quill.getContents()
      );
    },
    "form.en_description": function (val) {
      this.formModal.en_description = JSON.stringify(
        this.$refs.formen_description.quill.getContents()
      );
    },
  },
  mixins: [togglePasswordVisibility],
  directives: {
    Ripple,
  },
  mounted() {
    if (localStorage.getItem("NisourRole") == "admin"||localStorage.getItem("NisourRole") == "hr") {
      this.role = true;
    } else {
      this.role = false;
    }
    if (localStorage.getItem("NisourRole") == "admin"||localStorage.getItem("NisourRole") == "hr") {
      this.canEdit = true;
      if (localStorage.getItem("NisourRole") == "coordinator") {
      this.iD = localStorage.getItem("NisourDepartmentId");
    } 
    } else {
      this.canEdit = false;
    }
    
    if (localStorage.getItem("NisourRole") == "admin") {
      this.canDelete = true;
    } else {
      this.canDelete = false;
      this.search.department = localStorage.getItem("NisourDepartmentId");
    }
    this.ID(), this.gS(), this.gU(),this.gTD(),this.gSTD();
  },
  methods: {
    async gTD(){
await this.axios
        .get(
          `types?hr=true`
        )
        .then((res) => {
          this.types = [];
          this.types = res.data.items;
          this.search.types = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSTD(){
await this.axios
        .get(
          `subtypes`
        )
        .then((res) => {
          console.log(`this is the result ${res}`)
          this.subtypes = [];
          this.subtypes = res.data.items;
          this.search.subtypes = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gU(){
await this.axios
        .get(
          `users-name`
        )
        .then((res) => {
          this.users = [];
          this.users = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    checkModel() {
      this.selected = [];
    },
    resetCheckboxes() {
      this.selected = [];
    },
    async uM() {
      
      if (isNaN(this.editform.type_id)) {
        this.editform.type_id = this.editform.type_id["id"];
      }
      if (isNaN(this.editform.subtype_id)) {
        this.editform.subtype_id = this.editform.subtype_id["id"];
      }
      const ids = []
      if (this.editform.users.length>0) {
        this.editform.users.map(function(value, key) {
          ids.push(value.id);
        });
      }
      let data = new FormData();
      data.append("title", this.editform.title);
      data.append("number", this.editform.number);
      data.append("date", this.editform.date);
      data.append("type_id", this.editform.type_id);
      data.append("subtype_id", this.editform.subtype_id);
      data.append("description", this.editform.description);
      data.append("file", this.editform.file);
      ids.forEach((element) =>
        data.append("users[]", element))
      await this.axios
        .post(`documents/${this.editform.id}`, data,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {

        if (this.editform.users.length>0){
            for (let i = 0; i < this.editform.users.length; i++) {
            this.toEmail =this.editform.users[i].email,
            this.toName =this.editform.users[i].full_name,
            this.toID =this.editform.users[i].teacher_id,
            this.toUserID =this.editform.users[i].id,
            this.subject =this.editform.title,
            this.m = `سيد ${this.toName} : نود اعلامك بورود تعديل لكتاب رسمي يرجى فتح الحساب و تأكيد الاستلام عن طريق الرد على هذه الايميل. مع فائق التقدير`
            this.sendEmail(this.m,this.toName,this.toEmail);
            this.notifi.route = "employee-documents-list-notif";
            this.notifi.title = "كتاب رسمي وارد ";
            this.notifi.item_id = res.data.id;
            this.notifi.table = "documents";
            this.notifi.user_id = this.toUserID;
            this.notifi.type = "light-success";
            this.notifi.subtitle = ` ورد اليك كتاب رسمي `;
            this.notifi.bg = "bg-success";
            this.sendNoti();
          }}
      
            this.gS();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
          this.precentage=0
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    sendEmail(m,n,e) {
      const fromName = localStorage.getItem("NisourUserName")
      const fromEmail = localStorage.getItem("NisourEmail")
      emailjs.send(SID,TID,{
          from_name: fromName,
         to_name: n, 
          message: m,
          to_email: e,
          reply_to:fromEmail
        },AID)
        .then((result) => {
            this.makeToast(
              "success",
              "Notification Sender",
              `The notification has been sent successfully`
            );
            this.toName = ""
        }, (error) => {
            console.log('FAILED...', error.text);
        });
    },
    async aNM() {
      if (isNaN(this.form.type_id)) {
        this.form.type_id = this.form.type_id["id"];
      }
      if (isNaN(this.form.subtype_id)) {
        this.form.subtype_id = this.form.subtype_id["id"];
      }
      const ids = []
      if (this.form.users.length>0) {
        this.form.users.map(function(value, key) {
          ids.push(value.id);
        });
      }
       let data = new FormData();
      data.append("title", this.form.title);
      data.append("number", this.form.number);
      data.append("type_id", this.form.type_id);
      data.append("date", this.form.date);
      data.append("subtype_id", this.form.subtype_id);
      data.append("description", this.form.description);
      data.append("file", this.form.file);
      ids.forEach((element) =>
        data.append("users[]", element))
      await this.axios
        .post(`documents`, data,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          console.log(res.data)
          if (res.data.errors) {
            this.errorToast();
          } else {
            if (this.form.users.length>0){
            for (let i = 0; i < this.form.users.length; i++) {
            this.toEmail =this.form.users[i].email,
            this.toName =this.form.users[i].full_name,
            this.toID =this.form.users[i].teacher_id,
            this.toUserID =this.form.users[i].id,
            this.subject =this.form.title,
            this.m = `سيد ${this.toName} : نود اعلامك بورود كتاب رسمي يرجى فتح الحساب و تأكيد الاستلام عن طريق الرد على هذه الايميل. مع فائق التقدير`
            this.sendEmail(this.m,this.toName,this.toEmail);
            this.notifi.route = "documents-notif";
            this.notifi.title = "كتاب رسمي وارد ";
            this.notifi.item_id = res.data.id;
            this.notifi.table = "documents";
            this.notifi.user_id = this.toUserID;
            this.notifi.type = "light-success";
            this.notifi.subtitle = `ورد اليك كتاب رسمي من قبل ${this.fromName}`;
            this.notifi.bg = "bg-success";
            this.sendNoti();
          }}
            this.gS();
            this.makeToast(
              "success",
              "Addition Success",
              "Your addition is done successfully"
            );
          }
          this.rF();
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    rF() {
      this.form.title= "",
        this.form.numbe= "",
        this.form.date= "",
        this.form.type_id= "",
        this.form.subtype_id= "",
        this.form.description= "",
        this.form.file= ""
    },
    rEF() {
      this.editform.title= "",
        this.editform.numbe= "",
        this.editform.date= "",
        this.editform.type_id= "",
        this.editform.subtype_id= "",
        this.editform.description= "",
        this.editform.file= ""
    },
    async sendNoti() {
      await this.axios.post(`notifications`, this.notifi);
    },
    async dM() {
      await this.axios
        .delete(`documents/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async cM() {
      await this.axios
        .get(`documents/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async rDM(id) {
      await this.axios
        .get(`lecture/recycle/${id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
      this.editform.en_title = item.en_title;
      this.editform.ar_title = item.ar_title;
      this.editform.ar_description = item.ar_description;
      this.editform.en_description = item.en_description;
      this.editform.tags = item.tags;
      setTimeout(() => {
        
        if (this.editform.ar_description != null) {
          this.formModal.ar_edit = this.editform.ar_description
          this.$refs.editformar_description.quill.setContents(
            JSON.parse(this.editform.ar_description)
          );
        }
        if (this.editform.en_description != null) {
          this.formModal.en_edit = this.editform.en_description
          this.$refs.editformen_description.quill.setContents(
            JSON.parse(this.editform.en_description)
          );
        }
      }, 0);
      this.editform.ar_sub_description = item.ar_sub_description;
      this.editform.en_sub_description = item.en_sub_description;
      this.editform.department_id = item.department_id;
      this.editform.is_slider = item.is_slider;
      this.editform.is_aproved = item.is_aproved;
      this.editform.file = item.image;
      this.editform.id = item.id;
    },
    dRM(item) {
      this.editform.title = item.title;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gS(), 1000);
    },
    ID() {
      this.teacher_id = localStorage.getItem("NisourTeacherId");
    },
    async gS() {
      if (isNaN(this.search.type_id) & (this.search.type_id != "")) {
        this.search.type_id = this.search.type_id["id"];
      }
      if (isNaN(this.search.subtype_id) & (this.search.subtype_id != "")) {
        this.search.subtype_id = this.search.subtype_id["id"];
      }
      await this.axios
        .get(
          `documents?take=${this.perPage}&skip=${
            this.currentPage - 1
          }&type_id=${this.search.type_id}&fromdate=${this.search.fromdate}&todate=${this.search.todate}&subtype_id=${this.search.subtype_id}&title=${this.title}&user_id=${this.$route.params.id}`
        )
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
          
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    resetSearch() {
      (this.title = ""), (this.department = "");
      this.gS();
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    selectFilesForForm(e) {
      this.form.files = [];
      console.log(e.target.files);
      console.log(e.target.files[0]);
      e.target.files.forEach((element) => this.form.files.push(element));
      console.log(this.form.files);
    },
    selectFilesForEditForm(e) {
      this.editform.files = [];
      console.log(e.target.files);
      console.log(e.target.files[0]);
      e.target.files.forEach((element) => this.editform.files.push(element));
      console.log(this.form.files);
    },
    selectFileForForm(e) {
      this.form.files = "";
      console.log(e.target.files);
      console.log(e.target.files[0]);
      this.form.files = e.target.files[0];
      console.log(this.form.files);
    },
    selectFileForEditForm(e) {
      this.editform.file = "";
      console.log(e.target.files);
      console.log(e.target.files[0]);
      this.editform.file = e.target.files[0];
      console.log(this.editform.file);
    },
    view(id) {
      window.open(`https://nuc.edu.iq/blog/${id}`, "_blank");
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>